var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('h4',[_vm._v(_vm._s(_vm.$t("edit_information")))]),_c('validation-observer',{ref:"updateProfileValidation",attrs:{"name":"UpdateProfile"}},[_c('b-form',[(_vm.user && !_vm.showLoader)?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"profile-email-address","label":_vm.$t('email') + ':',"label-for":"profile-email-address"}},[_c('b-form-input',{attrs:{"id":"profile-email-address","type":"email","required":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.email"}})],1),_c('b-form-group',{attrs:{"id":"main-contact-tel-group","label":_vm.$t('mobile_phone') + ':',"label-for":"main-contact-tel"}},[_c('validation-provider',{attrs:{"name":"main-contact-tel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{attrs:{"id":"phoneCountries"}},[_c('b-form-select',{staticClass:"form-control",attrs:{"options":_vm.phoneCountriesList,"state":errors.length > 0 ? false : null},model:{value:(_vm.form.iso_code),callback:function ($$v) {_vm.$set(_vm.form, "iso_code", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.iso_code"}})],1),_c('b-form-input',{staticClass:"form-control",attrs:{"type":"number","id":"main-contact-tel","state":errors.length > 0 ? false : null},on:{"input":_vm.checkPhoneFormat},model:{value:(_vm.form.mobile),callback:function ($$v) {_vm.$set(_vm.form, "mobile", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.mobile"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))]),(_vm.phoneValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.phoneValidation))]):_vm._e()]}}],null,false,2204089357)})],1),_c('b-form-group',{attrs:{"id":"main-contact-gender-group","label":_vm.$t('gender') + ':',"label-for":"main-contact-gender"}},[_c('b-form-select',{attrs:{"id":"main-contact-gender","options":[
                  {
                    text: _vm.$t('select_one'),
                    value: null,
                    disabled: true,
                  },
                  { text: _vm.$t('mr'), value: '0' },
                  { text: _vm.$t('mrs'), value: '1' } ],"required":""},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.gender"}})],1),_c('b-form-group',{attrs:{"id":"profile-first-name","label":_vm.$t('first_name') + ':',"label-for":"profile-first-name"}},[_c('b-form-input',{attrs:{"id":"profile-first-name","required":""},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.first_name"}})],1),_c('b-form-group',{attrs:{"id":"profile-last-name","label":_vm.$t('last_name') + ':',"label-for":"profile-last-name"}},[_c('b-form-input',{attrs:{"id":"profile-last-name","required":""},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.last_name"}})],1),_c('b-form-group',{attrs:{"id":"profile-birthday","label":_vm.$t('birthday') + ':',"label-for":"profile-birthday"}},[_c('b-form-datepicker',{attrs:{"id":"profile-birthday","no-flip":"","required":""},model:{value:(_vm.form.birthday),callback:function ($$v) {_vm.$set(_vm.form, "birthday", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.birthday"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"company-address-group","label":_vm.$t('address') + ':',"label-for":"company-address"}},[_c('validation-provider',{attrs:{"name":"Company Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-address","state":errors.length > 0 ? false : null,"required":"","disabled":_vm.disableInputs},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,261243302)})],1),_c('b-form-group',{attrs:{"id":"company-address-nr-group","label":_vm.$t('address_nr') + ':',"label-for":"company-address-nr"}},[_c('validation-provider',{attrs:{"name":"Company Address Nr"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-address-nr","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.form.address_nr),callback:function ($$v) {_vm.$set(_vm.form, "address_nr", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.address_nr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,2807570868)})],1),_c('b-form-group',{attrs:{"id":"company-zip-group","label":_vm.$t('zip') + ':',"label-for":"company-zip"}},[_c('validation-provider',{attrs:{"name":"Company Zip"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-zip","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.form.zip),callback:function ($$v) {_vm.$set(_vm.form, "zip", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.zip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,911208486)})],1),_c('b-form-group',{attrs:{"id":"company-city-group","label":_vm.$t('city') + ':',"label-for":"company-city"}},[_c('validation-provider',{attrs:{"name":"Company City"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-city","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,3779300358)})],1),_c('b-form-group',{attrs:{"id":"domicile-country-group","label":_vm.$t('domicile_country') + ':',"label-for":"domicile-country"}},[_c('validation-provider',{attrs:{"name":"Country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"domicile-country","options":_vm.countries,"state":errors.length > 0 ? false : null,"required":"","disabled":_vm.disableInputs},model:{value:(_vm.form.country_id),callback:function ($$v) {_vm.$set(_vm.form, "country_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.country_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,1923504425)})],1),(_vm.company.role != 'buyer')?_c('b-form-group',{attrs:{"id":"profile-position","label":_vm.$t('position_in_the_company') + ':',"label-for":"profile-position"}},[_c('b-form-input',{attrs:{"id":"profile-position","required":""},model:{value:(_vm.form.position),callback:function ($$v) {_vm.$set(_vm.form, "position", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.position"}})],1):_vm._e()],1)],1):_vm._e(),(_vm.showLoader)?_c('b-col',{attrs:{"cols":"12"}},[_c('img',{attrs:{"src":"/new-loader.svg"}})]):_vm._e(),_c('b-row',{staticClass:"col-md-12 text-end justify-content-end p-0 pr-0 m-0"},[_c('b-button',{staticClass:"outlined mr-2",attrs:{"type":"button","variant":"outline-primary"},on:{"click":function($event){return _vm.$emit('showInfo', false)}}},[_vm._v(_vm._s(_vm.$t("close"))+" ")]),_c('b-button',{attrs:{"type":"button","variant":"primary"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v(_vm._s(_vm.$t("save"))+" ")])],1)],1)],1)],1),_c('b-card',[_c('h4',[_vm._v(_vm._s(_vm.$t("change_password")))]),_c('validation-observer',{ref:"updateProfileValidation",attrs:{"name":"UpdateProfile"}},[_c('b-form',[(_vm.user && !_vm.showLoader)?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"id":"current-password-group","label":_vm.$t('current_password'),"label-for":"current_password"}},[_c('validation-provider',{attrs:{"name":"Current Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"current_password","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs,"type":"password"},model:{value:(_vm.password.current_password),callback:function ($$v) {_vm.$set(_vm.password, "current_password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"password.current_password"}}),(_vm.passwordErrors && _vm.passwordErrors.current_password)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.passwordErrors.current_password[0]))]):_vm._e()]}}],null,false,851722661)})],1),_c('b-form-group',{attrs:{"id":"new-password-group","label":_vm.$t('new_password'),"label-for":"new_password"}},[_c('validation-provider',{attrs:{"name":"New Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"new_password","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs,"type":"password"},model:{value:(_vm.password.new_password),callback:function ($$v) {_vm.$set(_vm.password, "new_password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"password.new_password"}}),(_vm.passwordErrors && _vm.passwordErrors.new_password)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.passwordErrors.new_password[0]))]):_vm._e()]}}],null,false,463594341)})],1),_c('b-form-group',{attrs:{"id":"confirm-password-group","label":_vm.$t('confirm_password'),"label-for":"confirm_password"}},[_c('validation-provider',{attrs:{"name":"Confirm Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"confirm_password","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs,"type":"password"},model:{value:(_vm.password.confirm_password),callback:function ($$v) {_vm.$set(_vm.password, "confirm_password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"password.confirm_password"}}),(_vm.passwordErrors && _vm.passwordErrors.confirm_password)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.passwordErrors.confirm_password[0]))]):_vm._e()]}}],null,false,2907818469)})],1)],1)],1):_vm._e(),(_vm.showLoader)?_c('b-col',{attrs:{"cols":"12"}},[_c('img',{attrs:{"src":"/new-loader.svg"}})]):_vm._e(),_c('b-row',{staticClass:"col-md-12 text-end justify-content-end p-0 pr-0 m-0"},[_c('b-button',{attrs:{"type":"button","variant":"primary"},on:{"click":function($event){return _vm.changePassword()}}},[_vm._v(_vm._s(_vm.$t("save"))+" ")])],1)],1)],1)],1),_c('b-card',[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t("two_factor")))]),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t("two_factor_description")))]),(!_vm.is_twoFactor)?_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":_vm.openTwoFactorModal}},[_vm._v(" "+_vm._s(_vm.$t("change_status"))+" ")]):_vm._e()],1),_c('b-modal',{staticStyle:{"padding-top":"20vh"},attrs:{"scrollable":"","size":"md","hide-header":"","no-close-on-backdrop":"","hide-footer":""},model:{value:(_vm.twoFactor),callback:function ($$v) {_vm.twoFactor=$$v},expression:"twoFactor"}},[_c('div',[_c('two-factor',{on:{"toggle-two-factor":_vm.toggleTwoFactor}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }