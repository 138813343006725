<template>
    <div class="form_block">
        <div class="form_block_header ">
            <div class="col-md-12">
                <h4 class="row justify-content-between">
                    <b-card-title
                        class="font-weight-bold col-md-12 pl-0  pb-0 mb-0"
                        title-tag="h2"
                    >
                        {{ $t("two_factor_modal_title") }}
                    </b-card-title>

                </h4>
            </div>
        </div>
        <b-col class="px-xl-2 " lg="12" md="12" sm="12" style="padding: 20px;">
            <validation-observer ref="registerValidation">
                <b-form @submit.prevent class="auth-login-form mt-2">
                    <template v-if="regForm">
                       <p class="pt-0 pb-1"> {{ $t("two_factor_modal_description") }}</p>
                        <!--select twofactor variant-->
                        <b-form-group label-for="phone">
                            <validation-provider #default="{ errors }" class="d-flex" name="Phone">
                                <b-form-radio class="mr-3" name="some-radios" v-model="sendData.twoFactorType" value="email">
                                    E-Mail
                                </b-form-radio>
                                <b-form-radio name="some-radios" v-model="sendData.twoFactorType" value="phone">
                                    SMS
                                </b-form-radio>
                            </validation-provider>
                        </b-form-group>

                        <!-- phone -->
                        <!--<b-form-group v-if="sendData.twoFactorType == 'phone'" :label="$t('tel')" label-for="phone">-->
                        <!--<validation-provider-->
                        <!--#default="{ errors }"-->
                        <!--name="phone"-->
                        <!--rules="required"-->
                        <!--&gt;-->
                        <!--<b-form-input id="phone" v-model.trim="sendData.phone_email" placeholder="Your Phone"/>-->
                        <!--<small class="text-danger">{{ $t(errors[0]) }}</small>-->
                        <!--</validation-provider>-->
                        <!--</b-form-group>-->

                        <b-form-group
                            id="phoneGroup"
                            :label="$t('mobile') + ':'"
                            label-for="phone"
                            v-if="sendData.twoFactorType == 'phone'"
                        >
                            <b-input-group>
                                <b-input-group-prepend
                                    class="phoneCountries"
                                    id="phoneCountries"
                                >
                                    <b-form-select
                                        :options="phoneCountriesList"
                                        v-model="sendData.iso_code"
                                    >
                                    </b-form-select>
                                </b-input-group-prepend>
                                <b-form-input
                                    id="phone"
                                    @input="checkPhoneFormatSignator(sendData.phone_email, sendData.iso_code)"
                                    class="form-control"
                                    v-model="sendData.phone_email"
                                >
                                </b-form-input>
                            </b-input-group>
                            <span class="text-danger" v-if="phoneSignatorValidation">{{ phoneSignatorValidation }}</span>
                        </b-form-group>

                        <!-- email -->
                        <b-form-group :label="$t('email')" label-for="two_factor_email" v-if="sendData.twoFactorType == 'email'">
                            <validation-provider #default="{ errors }" name="two_factor_email" rules="email">
                                <b-form-input id="two_factor_email" placeholder="john@example.com" v-model.trim="sendData.phone_email"/>
                                <small class="text-danger">{{ $t(errors[0]) }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- submit buttons -->
                        <div class="d-flex justify-content-between align-items-center">
                            <b-button @click="sendCode" type="submit">
                                <img height="20" src="/new-loader.svg" v-if="resLoader"/> {{ $t("send_code") }}
                            </b-button>
                            <b-button @click="skip" class="ml-2" variant="outline-dark">
                                {{ $t("skip") }} >>
                            </b-button>
                        </div>
                    </template>
                    <template v-else>
                        <b-form-group :label="$t('code')" label-for="code">
                            <validation-provider #default="{ errors }" name="Email" rules="email">
                                <b-form-input id="code" placeholder="****" v-model.trim="code"/>
                            </validation-provider>
                        </b-form-group>
                        <b-button @click="confirmCode" type="submit">
                            <img height="20" src="/new-loader.svg" v-if="resLoader1"/> {{ $t("confirm") }}
                        </b-button>
                        <b-card-text class="text-center mt-2">
                            <b-card-text @click="resend()" role="button" v-if="!resent">
                                <span>{{ $t("resend_code") }}</span>
                            </b-card-text>
                            <b-card-text v-else>
                                <span> {{ countdown }} {{ $t("resend_verification_code") }} </span>
                            </b-card-text>
                        </b-card-text>
                    </template>

                </b-form>
            </validation-observer>
        </b-col>
    </div>
</template>

<script>
  import {ValidationObserver, ValidationProvider} from "vee-validate";
  import { phone } from "phone";
  import {
    BButton,
    BCardText,
    BCardTitle,
    BCol,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BLink,
    BRow,
    BInputGroup,
    BFormSelect
  } from "bootstrap-vue";

  export default {
    name: "TwoFactor",
    components: {
      BRow, BCol, BLink, BFormGroup, BFormInput, BFormCheckbox, BCardText,
      BCardTitle, BForm, BButton, BInputGroup, BFormSelect, ValidationProvider, ValidationObserver
    },
    data() {
      return {
        resLoader: false,
        resLoader1: false,
        regForm: true,
        code: null,
        sendData: {
          iso_code: null,
          phone_email: '',
          twoFactorType: "email"
        },
        disableInputs: false,
        role: null,
        resent: false,
        countdown: 60,
        phoneCountriesList: [],
        countries: [],
        phoneSignatorValidation: null
      }
    },

    created() {
      this.getTellCode()
    },

    methods: {
      sendCode() {
        this.resLoader = true;
        this.$http
            .post(`/twofactor/send-code`, this.sendData
            )
            .then((res) => {
              if (res) {
                this.resLoader = false;
                this.regForm = false;
              }
            });

        this.startCountdown();
      },

      skip() {
        this.$http
            .post(`/twofactor/close`)
            .then((res) => {
              if (res) {
                this.$emit('toggle-two-factor')
              }
            });
      },

      confirmCode() {
        this.resLoader1 = true;
        this.$http
            .post(`/twofactor/confirm-code`, {
              'code': this.code
            })
            .then((res) => {
              if (res) {
                this.resLoader1 = false;
                this.$emit('toggle-two-factor')
              }
            });
      },

      resend() {
        this.$http
            .post(`check-user-two-factor/${this.$store.state.auth.user.id}`)
            .then(() => {
              this.startCountdown();
            })

      },

      startCountdown() {
        this.resent = true;
        let timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            clearInterval(timer);
            this.resent = false;
            this.countdown = 60;
          }
        }, 1000);
      },

      getTellCode() {
        this.$http.post("/countries").then((res) => {
          res.data.value.map((item) => {
            this.phoneCountriesList.push({
              value: item.iso_code,
              text: `${item.iso_code} (${item.phone_code})`,
            });

            this.countries.push({
              value: item.id,
              text: item.name,
            });
          });
        });
      },

      checkPhoneFormatSignator(val, code) {
        if (val) {
          let validation = phone(val, {
            country: code,
            validateMobilePrefix: false
          });
          if (validation && validation.isValid == false) {
            this.phoneSignatorValidation = `Phone for ${code} must be valid`;
          } else {
            this.phoneSignatorValidation = "";
          }
        }
      },

    }
  }
</script>

<style lang="scss">
    @import "@core/scss/vue/pages/page-auth.scss";

    .form_block {
        background-color: white;
        width: 100%;
        border-radius: 25px;
        overflow: hidden;
        height: fit-content;
        /*margin-top: 160px*/
    }

    .form_block .form_block_header {
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #555;
        padding: 20px;
    }

    .form_block .form_block_header h2, .form_block .form_block_header span {
        color: #222f28;
    }

    .form_block input, .form_block select {
        background-color: #F7F6F4;
    }

    /*.form_block button {*/
    /*background-color: #577264 !important;*/
    /*}*/
</style>
<style scoped>

</style>