<template>
  <b-card>
    <h3 class="">{{ $t("my_profile") }}</h3>
    <h4> <feather-icon size="20" icon="UserIcon" class="mr-50" />{{ $store.state.auth.user.first_name }} {{ $store.state.auth.user.last_name }}</h4>
  </b-card>
</template>

<script>

export default {

  props: {},
  data() {
    return {
      Selected: "",
    };
  },
};
</script>
