<template>
  <b-card>
    <b-row>
      <b-col
        v-if="latestImage"
        xl="12"
        lg="12"
        md="12"
        cols="12"
        class=""
        align="center"
      >
      <h4 class="justify-content-center">
          {{$t('profile_picture')}}
      </h4>
    
        <b-link>
          <b-avatar variant="primary" class="mr-2"  size="12rem"></b-avatar>
     
        </b-link>
        <br />

      </b-col>
    </b-row>
  </b-card>
</template>

<script>
const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  props: ["latestImage"],
  data() {
    return {
      mainProps: {
        blank: false,
        blankColor: "#777",

        class: "m1",
      },
      image: null,
      imageSrc: null,
      images: [],
      file1: null,
      file2: null,
      avatar: "",
      showAvatar: false,
    };
  },
  created() {},
  computed: {},

  watch: {
    file1(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.latestImage.img = value;
            })
            .catch(() => {
              this.latestImage.img = null;
            });
          this.showAvatar = false;
        } else {
          this.imageSrc = null;
        }
      }
    },
  },

  methods: {
    updateImage() {
      this.$store.commit("app/SET_AVATAR_HEADER", "");
      this.$store.commit("app/SET_PROFILE_HEADER", "");
      if (this.showAvatar) {
        this.$store.commit("app/SET_AVATAR_HEADER", this.avatar);
      } else if (this.latestImage.img) {
        this.$store.commit("app/SET_PROFILE_HEADER", this.latestImage.img);
      }
      document.getElementById("header-info").click();
    },
    changeAvatar(item) {
      this.avatar = item;
      this.showAvatar = true;
    },
  },
};
</script>
