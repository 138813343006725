<template>
  <b-card>
    <div>
      <h3 class="mb-1">
        {{ $t("welcome") }}
      </h3>
     
    </div>
  </b-card>
</template>

<script>

export default {
 
  props: {},
  data() {
    return {
      Selected: "",
    };
  },
};
</script>
